<ng-container *ngIf="!isOnline">
    <div class="text-center w-100 h-100 d-flex justify-content-center align-items-center" style="flex-direction: column;">
        <img src="../assets/images/data-not-found/NO INTERNET.svg"/>
        <div class="w-100 text-center">
            <button mat-flat-button color="primary" class="m-t-32" (click)="retry()">
                Retry
            </button>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="isOnline">
    <app-loader></app-loader>
    <router-outlet></router-outlet>
</ng-container>
