import { Injectable } from '@angular/core';
import { CryptoService } from './crypto.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private crypto : CryptoService) { }

  // Method to store an object in localStorage
  setObject(key: string, value: any): void {
    localStorage.setItem(key, this.crypto.encrypt(value));
  }

  // Method to retrieve an object from localStorage
  getObject(key: string): any {
    const item = localStorage.getItem(key);
    if (item == null && item == undefined) { return null; }
    return this.crypto.decrypt(item);
  }

  // Method to remove an object from local storage
  removeObject(key: string): void {
    localStorage.removeItem(key);
  }

  // Method to clear all objects from local storage
  clearStorage(): void {
    localStorage.clear();
  }

}
