import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { StorageItemType } from '../constants/storageitem';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public token = ''
  loaderSetValue$ = new BehaviorSubject(false);
  userPermissions: string[] = [];

  constructor(private storage: StorageService) { }

  isAuthorized() {
    return Boolean(this.storage.getObject(StorageItemType.TOKEN)) && Boolean(this.storage.getObject(StorageItemType.REFRESH_TOKEN))
  }

  setToken(token: any) {
    this.storage.setObject(StorageItemType.TOKEN, token);
  }

  setLoader(value:boolean){
    this.loaderSetValue$.next(value)
  }

  hasPermission(menu) {
    return this.userPermissions.includes(menu);
  }
}
