import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Constant } from '../constants/constant';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environments } from 'src/environments/environment';
import { StorageItemType } from '../constants/storageitem';
import { AuthService } from './auth.service';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  BASE_URL: string = environments.baseUrl;

  private languageListSubject = new BehaviorSubject<any[]>(this.getStoredLanguages());
  languageList$ = this.languageListSubject.asObservable();

  private formatErrors(error: any) {
    return throwError(error);
  }

  constructor(
    public http: HttpClient,
    private apiService: ApiService,
    private router: Router,
    private storageService: StorageService,
    private authService: AuthService,
    private dialog : MatDialog,
    private userService : UserService
  ) { }

  sendOTP(payload: any) {
    return this.apiService.post(Constant.sendOTP, payload);
  }

  validateOTP(payload: any) {
    return this.apiService.post(Constant.validateOTP, payload);
  }

  userRegister(payload: any) {
    return this.apiService.post(Constant.userRegister, payload);
  }

  getAllLanguages() {
    return this.apiService.get(Constant.languageList);
  }

  updateUser(payload: any) {
    return this.apiService.put(Constant.updateUser, payload);
  }

  updateLanguage(userId, languageId) {
    return this.apiService.put(Constant.updateLanguage + '/' + userId + '/' + languageId, {});
  }

  refreshToken(payload: any) {
    return this.apiService.post(Constant.refreshToken, payload);    
  }

  revokeToken(payload:any){
    return this.apiService.post(Constant.revokeToken, payload);
  }

  logOut() {

    // Disabled below code if we are using HTTP only cookies from browser directly in interceptor.
    let refreshToken = this.storageService.getObject(StorageItemType.REFRESH_TOKEN);
    if (refreshToken != null) {
      refreshToken = refreshToken.replace(/^"(.*)"$/, '$1');
    }

    const payload = {
      refreshToken: refreshToken
    }

    this.revokeToken(payload).subscribe({
      next: res => {
        if (res?.success) { }
      }, error: (error) => {
        
      }
    })

    this.storageService.clearStorage();
    this.dialog.closeAll();
    this.router.navigate(['auth/login']);
    // this.authService.setLoader(false);
  }

  fetchLanguages() {
    this.getAllLanguages().subscribe({
      next: (res: any) => {
        const languages = res?.data || [];
        this.languageListSubject.next(languages);
        this.storeLanguages(languages);
      },
      error: (error) => {
        
      }
    });
  }

  getLanguageList(): Observable<any[]> {
    return this.languageList$;
  }

  storeLanguages(languages: any[]) {
    const encryptedLanguageList = languages;
    this.storageService.setObject(StorageItemType.LANGUAGE_LIST, encryptedLanguageList)
  }

  getStoredLanguages(): any[] {
    const storedLanguages = this.storageService.getObject(StorageItemType.LANGUAGE_LIST);
    return storedLanguages ? JSON.parse(storedLanguages) : [];
  }
  

  setUser(res, adminUserId){
    const encryptedToken = res?.data?.sendOtpResponse.tokenDetails?.token
    this.authService.setToken(encryptedToken);
    const rToken = res?.data?.sendOtpResponse.tokenDetails?.refreshToken;
    const encryptedRefreshToken = rToken;
    this.storageService.setObject(StorageItemType.REFRESH_TOKEN, encryptedRefreshToken);

    if (res?.data?.validateOTPResponse.userId) {
      const encryptedUserId = res?.data?.validateOTPResponse.userId;
      this.storageService.setObject(StorageItemType.USER_ID, encryptedUserId);

      const encryptedGarageId = res?.data?.validateOTPResponse.garageId;
      this.storageService.setObject(StorageItemType.GARAGE_ID, encryptedGarageId);
    } else {
      this.storageService.setObject(StorageItemType.USER_ID, '');
    }

    const encryptedMobileNumber = res?.data?.validateOTPResponse.mobileNumber;
    this.storageService.setObject(StorageItemType.MOBILE_NUMBER, encryptedMobileNumber);

    this.userService.getLoggedInUserInfoWithAccessDetails(res?.data?.validateOTPResponse.userId, false).subscribe({
      next: (data) => {
        // Handle the emitted data

        if(data?.data) {
          var accessPermissions = data?.data['userMenuAccess'];
          delete data?.data['userMenuAccess'];
  
          // Log user data in google analytics
          this.logUserInfoInGoogle(data?.data['userId'], data?.data['roleName']);
  
          const encryptedUserProfile = JSON.stringify(data.data)
          this.storageService.setObject(StorageItemType.USER_PROFILE, encryptedUserProfile);
  
          const userPermission = JSON.stringify(accessPermissions)
          const encryptedUserPermission = userPermission;
  
          this.storageService.setObject(StorageItemType.USER_PERMISSIONS, encryptedUserPermission);
        } 

        const adminId = Number(this.storageService.getObject(StorageItemType.ADMIN_LOGIN_ID))
        if(adminId == adminUserId) {
          this.storageService.removeObject(StorageItemType.IS_ADMIN)
          this.storageService.removeObject(StorageItemType.ADMIN_LOGIN_ID)
        } else {
          const encryptedIsAdmin = true;
          this.storageService.setObject(StorageItemType.IS_ADMIN, encryptedIsAdmin);

          const encryptedAdminUserId = adminUserId;
          this.storageService.setObject(StorageItemType.ADMIN_LOGIN_ID, encryptedAdminUserId)
        }


        this.router.navigate(['web/dashboard/dashboard1']);
        // this.authService.setLoader(false);
        window.location.reload();
      },
      error: (error) => {
        // Handle errors
        // console.error('An error occurred:', error);
      },
      complete: () =>  {}   });
  }

  logUserInfoInGoogle(userId: any, userRole: string){
    // Push userLogin event data to GTM data layer
    
   try {
     window['dataLayer'] = window['dataLayer'] || [];
     window['dataLayer'].push({
       event: 'userLogin',
       user_id: userId,
       user_role: userRole
     });
   } catch (ex) {
    
   }
  }
  
}
