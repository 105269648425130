import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environments } from 'src/environments/environment';
import { ApiService } from './shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class CustomTranslationLoaderService implements TranslateLoader {
  baseUrl = environments.baseUrl;
  constructor(private http: HttpClient, private apiService : ApiService) {}

  getTranslation(lang: string): Observable<any> {
    // const headers:any = new HttpHeaders({
    //   'Content-Type': 'application/json' });
    // return this.http.get(`${this.baseUrl}Language/${lang}`,
    //   { headers })
    //   .pipe(catchError(this.formatErrors));
    return this.http.get(`assets/i18n/${lang}.json`);
  }

  private formatErrors(error: any) {
    return throwError(error);
}
}
