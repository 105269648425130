export class Validators_Patterns {
    
    public static MAX_ALLOWED_NUMBER_10000 = /^(?:10000(?:\.00?)?|\d{1,4}(?:\.\d{1,2})?)$/;
    public static MAX_ALLOWED_NUMBER_20000 = /^(?:0|[1-9][0-9]{0,3}|[1][0-9]{4}|20000)$/;
    public static MAX_ALLOWED_NUMBER_10 = "^(10|[1-9])$";
    public static MIN_ALLOWED_NUMBER_0 = /^\d{0,2}(\.\d)?$/;
    public static MOBILE_NUMBER = '^[0-9]*$';
    public static DISCOUNT_NUMBER = /^(100|\d{1,2})%?$/;
    public static FIRST_NAME = "^[a-zA-Z]+(?: [a-zA-Z]+)*$";
    public static MIDDLE_NAME = "^[a-zA-Z]+(?: [a-zA-Z]+)*$";
    public static LAST_NAME = "^[a-zA-Z]+(?: [a-zA-Z]+)*$";
    public static VEHICLE_NUMBER = "^[A-Z]{2}\\s?\\d{2}\\s?[A-Z]{1,2}\\s?\\d{4}$";
    public static DAILY_TRAVEL = "^([1-9]|[1-9][0-9]|[12][0-9]{2}|300)$";
    public static POLICY_NUMBER = "^[a-zA-Z0-9]+$";
    public static PUC_NUMBER = "^[a-zA-Z0-9]+$";
    public static NAME = '^[a-zA-Z0-9]+(?:[- ][a-zA-Z0-9]+)*$';
    public static MECHANICS = '^(?:[1-9]|[1-4][0-9]|50)$';
    public static CAPACITY = '^([0-9]|1[0-9]|20)$';
    public static SERVICE_TIME = '^[1-8]\d*$';
    public static REQUIRED_AMOUNT = '^[1-9][0-9]*$';
    public static DISCOUNT_VALIDATOR = '^[1-9]?[0-9]{1}$|^100$';
    public static DESCRIBE_PROBLEM =/^.{0,250}$/;
    public static ROLE_NAME = "^[a-zA-Z]+(?: [a-zA-Z]+)*$";
    public static NAME_NUMBER = "^[a-zA-Z0-9]+$";
    // public static EXTRA_SPACE_REMOVE = /\s+/g;
    public static EXTRA_SPACE_REMOVE = /[^a-zA-Z0-9.]/g;
    public static MAX_ALLOWED_500_CHARACTER = "^.{0,500}$";
    public static UPI_ID = /^[A-Za-z0-9]+@[A-Za-z0-9]+$/;
    

}