import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { environments } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {

  isOnline = true;
  isCountryAccess = true;
  imageBaseUrl = environments.cdnUrl;

  constructor(public translate: TranslateService, private router: Router) {
    // translate.addLangs(['en', 'hi','gu','ma']);
    // translate.setDefaultLang('en');
    this.isOnline = navigator.onLine;
    this.findLocation();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // Push page view event to GTM data layer
      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push({
        event: 'page_view',
        page_path: event.urlAfterRedirects
      });
    });
  }

  findLocation(): void {
    // this.fetchCountryCodeFromIP();
  }

  private async fetchCountryCodeFromIP(): Promise<void> {
    try {
      const url = 'https://ipapi.co/json/';
      const response = await fetch(url);
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      const countryCode = data.country?.toUpperCase() || '';
  
      this.isCountryAccess = countryCode === 'IN';
    } catch (error) {
      this.handleError('Error fetching country data from IP API', error);
    }
  }
  
  private handleError(message: string, error: any): void {
    console.error(message, error);
    this.setOfflineStatus();
  }
  
  private setOfflineStatus(): void {
    this.isOnline = false;
  }

  retry() {
    window.location.reload();
  }
}
