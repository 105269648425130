
export class ServiceStatus {

    public static All = 'All';
    public static Booked = 'BOOKED';
    public static InProgress = 'INPRG';
    public static ApprovalPending = 'APPPND';
    public static OnHold = 'ONHOLD';
    public static Complete = 'CMPLT';
    public static Invoiced = 'INVOCD';
    public static PaymentDue = 'PAYDUE';
    public static Reject = 'REJCTD';
    public static Cancel = 'CANCEL';
    public static NotStart = 'NOTSTRT';
    public static PickUpPending = 'PCKPPND';
    public static PickUpApproved = 'PCKAPRD';
    public static PickUpRejected = 'PCKRJCTD';
    public static Expired = 'EXPIRED'

}