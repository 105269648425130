<h2 mat-dialog-title class="m-b-8">{{ "login_as" | translate }} {{ "user" | translate }}</h2>
<mat-divider></mat-divider>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="userDetail">
    <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16">{{ "user" | translate }}<i class="text-error">*</i></mat-label>
    <mat-form-field appearance="outline" class="w-100" (click)="onSearchValueClear();">
        <mat-select value="" formControlName="user" matTooltip='{{ "s_user" | translate }}'>
        <div class="searchIcon">
          <i-tabler name="search" class="icon-20 d-flex"></i-tabler>
          <input (keyup)="onKey($event)"  formControlName="searchControl" class="searchbar" placeholder="Search bar..." />
        </div>
        <ng-container *ngFor="let item of allUserListData">
          <mat-option [value]="item.userId" *ngIf="item?.firstName && item?.lastName">{{item?.firstName + ' ' + item?.lastName + '  ' + item?.mobileNumber + '  ' + item?.roleName}}</mat-option>
        </ng-container>
        <mat-option *ngIf="!allUserListData.length" disabled><span>{{ "n_r_found" | translate }}</span></mat-option>
        </mat-select>
        <mat-hint *ngIf="
                    userDetail.controls['user'].touched && userDetail.controls['user'].invalid
                " class="m-b-16 error-msg">
            <div class="text-error" *ngIf="
                    userDetail.controls['user'].errors &&
                    userDetail.controls['user'].errors['required']
                    ">
                {{ "user" | translate }}
                {{ "is_required" | translate }}
            </div>
        </mat-hint>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions class="p-24 justify-content-end">
  <button
    mat-stroked-button
    color="warn"
    mat-dialog-close
  >
  {{'cancel' | translate}}
  </button>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true"
    (click)="loginAs()"
  >
  {{'login_as' | translate}}
  </button>
</mat-dialog-actions>