// src/app/services/gtm.service.ts
import { Injectable } from '@angular/core';
import { environments } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class GtmService {

  loadGtm() {
    const gtmId = environments.gtmContainerId;
    if (gtmId) {
      // Insert script tag in head
      const gtmScript = document.createElement('script');
      gtmScript.async = true;
      gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
      document.head.insertBefore(gtmScript, document.head.firstChild);

      const dataLayerScript = document.createElement('script');
      dataLayerScript.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmId}');
      `;
      document.head.insertBefore(dataLayerScript, document.head.firstChild);

      // Insert noscript tag in body
      const noscriptTag = document.createElement('noscript');
      noscriptTag.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
      document.body.insertBefore(noscriptTag, document.body.firstChild);
    }
  }

}
