import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {

  isOnline = true;

  constructor(public translate: TranslateService, private router: Router) {
    // translate.addLangs(['en', 'hi','gu','ma']);
    // translate.setDefaultLang('en');
    this.isOnline = navigator.onLine;
    this.findLocation();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // Push page view event to GTM data layer
      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push({
        event: 'page_view',
        page_path: event.urlAfterRedirects
      });
    });
  }

  findLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => this.processLocation(position.coords.latitude, position.coords.longitude),
        (error: any) => this.handleError('Error getting location', error)
      );
    } else {
      this.setOfflineStatus();
    }
  }
  
  private async processLocation(lat: number, lng: number): Promise<void> {
    try {
      const countryCode = await this.fetchCountryCodeFromOSM(lat, lng);
      this.isOnline = countryCode === 'IN';
    } catch (error) {
      this.handleError('Error fetching country data', error);
    }
  }
  
  // Fetch country code using OpenStreetMap's Nominatim (no API key required)
  private async fetchCountryCodeFromOSM(lat: number, lng: number): Promise<string> {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&zoom=10&addressdetails=1`;
    
    const response = await fetch(url);
  
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  
    const data = await response.json();
  
    return data.address.country_code?.toUpperCase() || ''; // Get country code
  }

  private handleError(message: string, error: Error): void {
    console.error(`${message}:`, error);
    this.setOfflineStatus();
  }

  private setOfflineStatus(): void {
    this.isOnline = false;
  }

  retry() {
    window.location.reload();
  }
}
