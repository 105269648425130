import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environments } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  BASE_URL: string = environments.baseUrl;

  constructor(private http: HttpClient) { }

  private formatErrors(error: any) {
    return throwError(error);
}

  // GET METHOD
  get(path?: string, params: HttpParams = new HttpParams()): Observable<any> {
    const headers:any = new HttpHeaders({
      'Content-Type': 'application/json'    });
    return this.http.get(this.BASE_URL + path,
      { params, headers })
      .pipe(catchError(this.formatErrors));
  }

  // getLanguage(path: string, params: HttpParams = new HttpParams()): Observable<any> {
  //   const headers:any = new HttpHeaders({
  //     'Content-Type': 'application/json'    });
  //   return this.http.get(path,
  //     { params, headers })
  //     .pipe(catchError(this.formatErrors));
  // }

  getData(path?: string, params: HttpParams = new HttpParams()): Observable<any> {
    const headers:any = new HttpHeaders({
      'Content-Type': 'application/json'    });
    return this.http.get(this.BASE_URL + path,
      { params, headers })
      .pipe(catchError(this.formatErrors));
  }

  // POST METHOD  
  post(path?: string, body: Object = {}): Observable<any> {
    const headers:any = new HttpHeaders({
      'Content-Type': 'application/json'});
    return this.http.post(this.BASE_URL + path,
      body, {headers})
      .pipe(catchError(this.formatErrors));
  }

  postFileUpload(path: string, formData: FormData): Observable<any> {
    return this.http.post(this.BASE_URL + path, formData)
      .pipe(
        catchError(this.formatErrors)
      );
  }

  postData(path?: string, body: Object = {}): Observable<any> {
    const headers:any = new HttpHeaders({
      'Content-Type': 'application/json'});
    return this.http.post(this.BASE_URL + path,
      body, {headers})
      .pipe(catchError(this.formatErrors));
  }

  documentDownload(path?: string, body: Object = {}): Observable<any> {
    const headers:any = new HttpHeaders({
      'Content-Type': 'application/json '});
    return this.http.post(this.BASE_URL + path,
      body, {responseType: 'blob' , headers})
      .pipe(catchError(this.formatErrors));
  }



// PUT METHOD
  put(path?: string, body: Object = {}): Observable<any> {
    const headers:any = new HttpHeaders({
      'Content-Type': 'application/json'    });
    return this.http.put(this.BASE_URL + path!,
      body, { headers })
      .pipe(catchError(this.formatErrors));
  }

  putData(path?: string, body: Object = {}): Observable<any> {
    const headers:any = new HttpHeaders({
      'Content-Type': 'application/json'    });
    return this.http.put(this.BASE_URL + path!,
      body, { headers })
      .pipe(catchError(this.formatErrors));
  }

  // DELTE METHOD
  delete(path?: string, body: Object = {}): Observable<any> {
    return this.http.delete(this.BASE_URL + path, body)
      .pipe(catchError(this.formatErrors));
  }

  deleteData(path?: string, body: Object = {}): Observable<any> {
    return this.http.delete(this.BASE_URL + path, body)
      .pipe(catchError(this.formatErrors));
  }
}

