import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { CoreService } from 'src/app/shared/services/core.service';
import { environments } from 'src/environments/environment';

@Component({
  selector: 'app-branding',
  standalone: true,
  imports: [NgIf],
  template: `
    <div class="branding">
      <a href="/web/dashboard/dashboard1" *ngIf="options.theme === 'light'">
        <img loading="lazy"
          [src]="imageBaseUrl + '/assets/images/logos/main-logo.png'"
          class="align-middle m-2"
          alt="logo"
          width="160px"
        />
      </a>
      <a href="/web/dashboard/dashboard1" *ngIf="options.theme === 'dark'">
        <img loading="lazy"
          [src]="imageBaseUrl + '/assets/images/logos/light-logo.svg'"
          class="align-middle m-2"
          alt="logo"
          width="160px"
        />
      </a>
    </div>
  `,
})
export class BrandingComponent {
  options = this.settings.getOptions();
  imageBaseUrl = environments.cdnUrl;
  
  constructor(private settings: CoreService) {}
}
