import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  loading : boolean = false;
  constructor(private loaderService :AuthService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.loaderService?.loaderSetValue$?.subscribe((res:boolean)=>{
      this.loading = res;
      this.cdr.detectChanges();
    })
  }
}
