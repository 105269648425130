import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { StorageItemType } from '../constants/storageitem';
import { PermissionTypeCode } from '../constants/permissioncode';

@Directive({
  selector: '[appPermission]'
})
export class PermissionDirective implements OnInit{

  private permissionData: any;
  permissions: any[];
  @Input() menuCode: string;
  @Input() accessCode: string;

  constructor(private el: ElementRef, private renderer: Renderer2, private storage : StorageService) {}

  ngOnInit() {

    const data = JSON.parse(this.storage.getObject(StorageItemType.USER_PERMISSIONS));
    this.permissions =JSON.parse(data);

    const allowedMenu = this.permissions.find(permission => permission.menuCode === this.menuCode && permission.permissionTypeCode === PermissionTypeCode.Deny && permission?.hasPermission);
    const disabledMenu = this.permissions.find(permission => permission.menuCode === this.menuCode && permission.permissionTypeCode === this.accessCode && !permission?.hasPermission);

    if (allowedMenu || disabledMenu) {
      this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
    }
    
  }
}

