export class Constant {
       
    public static authLogin = 'Auth/login';
    public static sendOTP = 'Auth/send/otp';
    public static validateOTP = 'Auth/validate/otp';
    public static userRegister = 'User/register';
    public static languageList = 'StaticValues/language/list';
    public static brandList = 'Vehicle/brands';
    public static brandModel = 'Vehicle/models';
    public static modelVarient = 'Vehicle/variants';
    public static vehicleAdd = 'Vehicle/add';
    public static vehicleList = 'Vehicle/all';
    public static deleteVehicle = 'Vehicle/remove/user';
    public static updateUser = 'User/update';
    public static editVehicle = 'Vehicle/detail';
    public static updateVehicle = 'Vehicle/update';
    public static documentUpload = 'Document/Upload'
    // public static manageLanguage = '/StaticValues/language/list';
    public static documentDownload = 'Document/Download';
    public static documentDelete = 'Document/Delete';
    public static refreshToken = 'Auth/refresh-token';
    public static userAddress = 'User/address';
    public static userAddresses = 'User/addresses';
    public static userList = 'User/all';
    public static userRole = 'User/role';
    public static postCode = 'User/zipcode';
    public static deactivateUser = 'User/deactivate';
    public static userProfile = 'User/profile';
    public static userLoginAccessInfo = 'User/login/info';
    public static updateUserProfile = 'User/update';
    public static basicGarageInfo = 'Garage/Basic';
    public static updateBasicGarageInfo = 'Garage/Basic';
    public static addressGarageInfo = 'Garage/Address';
    public static extrasGarageInfo = 'Garage/Extras';
    public static brandGarageInfo = 'Garage/Brand';
    public static bannerGarageInfo = 'Garage/Banner';
    public static scheduleGarageInfo = 'Garage/Schedule';
    public static serviceTypeGarageInfo = 'Garage/ServiceType';
    public static garageList = 'Garage';
    public static garageAllDay = 'Garage/AllDay';
    public static bookingService = 'Service/ServiceType/All';
    public static bookingPackages = 'Service/Service/Packages';
    public static standardBooking = 'Service/ServiceType/Standard';
    public static bookingServiceTypeStandard = 'Service/ServiceType/Standard';
    public static ownerName = 'User/unassigned';
    public static deleteGarage = 'Garage';
    public static accessControlRole = 'AccessControl';
    public static accessControlPost = 'AccessControl';
    public static BookingInvioce = 'Service/Invoice';
    public static serviceAll = 'Service/ServiceType/All';
    public static serviceTypeOffer = 'Garage/service/type/offer';
    public static serviceTypePrice = 'Garage/package/service/price';
    public static servicePackageOffer = 'Garage/package/service/offer';
    public static loggingExceptions = 'Logging/Exceptions';
    public static deleteException = 'Logging/Exception';
    public static searchGarage = 'Service/Search/Garage';
    public static vehicleModel = 'Vehicle/Model';
    public static vehicleVariant = 'Vehicle/Variant';
    public static bookingPackage = 'Service/ServiceType/Package';
    public static bookingAddOns = 'Service/AddOns/All'
    public static serviceTypeAddOns = 'Service/AddOns'
    public static bookingTimeSlot = 'Service/TimeSlots';
    public static garageTimeSlot = 'Garage/Timeslot';
    public static garageBookingTimeSlot = 'Service/Garage/TimeSlots';
    public static garageBook = 'Service/BookService';
    public static garageEmployee = 'Garage/employee';
    public static employeeProfileImage = 'Garage/employee/profile/image';
    public static getUserAddress = 'User/address';
    public static getAddresses = 'User/addresses';
    public static getEmployeeData = 'Garage/employees';
    public static roleAdd = 'Role/Add';
    public static roleUpdate = 'Role/Update';
    public static vehicleBrand = 'Vehicle/Brand';
    public static getlanguageList = 'Language/';
    public static getOngoingList = 'Garage/ongoing/services';
    public static serviceAccept= 'Service/Accept';
    public static serviceReject= 'Service/Reject';
    public static rejectReason  = 'Service/Cancellation/Rejection/Reasons'
    public static getServiceType = 'Garage/service/types';
    public static basicInfo = 'Garage/basic/info';
    public static addressDetailsInfo = 'Garage/Address';
    public static garageImage = 'Garage/image';
    public static garageBrands = 'Garage/brands';
    public static garageSchedule = 'Garage/schedule';
    public static garageServiceType = 'Garage/service/types';
    public static garageCapacity = 'Garage/timeslot/capacity';
    public static userProfileImage = 'User/profile/image';
    public static ongoingServiceId = 'Vehicle/Service/Details';
    public static getJobCard = 'Service/JobCard/Details';
    public static jobCard = 'Service/JobCard/Create';
    public static ServiceHistoryList = 'Vehicle/All/Services/History';
    public static ServiceCancel = 'Service/Cancel';
    public static pickupOtpGenerate = 'Service/PickupDrop/Otp/Generate';
    public static pickupDropOtp = 'Service/PickupDrop/Otp';
    public static serviceInvoice = 'Service/Invoice';
    public static verifyOtp = 'Service/PickupDrop/Otp/Verify';
    public static serviceStatus = 'Service/Statuses';
    public static serviceStatusUpdate = 'Service/Status/Update';
    public static revokeToken = 'Auth/revoke-token';
    public static invoiceItem = 'Service/Invoice/Items';
    // public static offerPriceEstimate = 'Service/Package/Offer/PriceEstimate';
    public static offerPriceEstimate = 'Service/Details/Generate/Invoice';
    public static feedBack = 'Service/Feedback';
    public static updateLanguage = 'User/language';
    public static fuelType = 'Vehicle/FuelTypes';
    public static invoiceEdit = 'Service/Invoice/Edit';
    public static invoiceView = 'Service/Invoice';
    public static serviceCancel = 'Service/Cancel';
    public static singleServiceHistory = 'Vehicle/Service/History';
    public static serviceReschedule = 'Service/Reschedule';
    public static userLogs = 'Logging/UserLogs';
    public static deleteUserLogs = 'Logging/UserLog';
    public static oilCoolantEngine = 'Vehicle/OilCoolantNoiseLevels';
    public static packagePriceOffer = 'Garage/package/service/price';
    public static feedBackSave = 'Feedback/Save';
    public static deleteAllUserLogs = 'Logging/UserLogs/All';
    public static garageActive = 'Garage/activate';
    public static jobCardIsSave = 'Service/JobCard/IsSaved';
    public static loginAs = 'Auth/login-as';
    public static couponList = 'Coupon/All';
    public static removeCoupon = 'Coupon/Delete';
    public static addUpdateCoupon = 'Coupon/Add/Update';
    public static deleteAllLog = 'Logging/Exceptions/All';
    public static applyCoupon = 'Coupon/Apply';
    public static servicePaymentList = 'Service/Payment/Status/List';
    public static configuration = 'Garage/payment/configuration';
    public static removePaymentConfig = 'Garage/payment/configuration';
    public static paymentMethodList = 'Service/Payment/Methods/List';
    public static requestUserOtp = 'User/request-otp';
    public static verifyUserOtp = 'User/verify-otp';
    public static totalService = 'UserReport/Current/Year/ServiceTotal';
    public static completedInYear = 'UserReport/Services/Completed/InYear';
    public static completedInLastYear = 'UserReport/Services/Completed/LastYear';
    public static recentService = 'UserReport/Recent/Services';
    public static vehicleCurrentStatus = 'UserReport/Vehicle/Current/Status';
 }
