import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TablerIconsModule } from 'angular-tabler-icons';
import { MaterialModule } from 'src/app/material.module';
import { StorageItemType } from 'src/app/shared/constants/storageitem';
import { LoginService } from 'src/app/shared/services/login.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-login-as-dialog',
  templateUrl: './login-as-dialog.component.html',
  styleUrls: ['./login-as-dialog.component.scss'],
  standalone: true,
  imports: [
    MaterialModule,
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    TablerIconsModule
  ],
})
export class LoginAsDialogComponent {

  allUserList:any = [];
  allUserListData:any = [];
  userDetail : FormGroup;
  adminUserId:any

  constructor(private storage : StorageService,
    private userService : UserService,
    private fb : FormBuilder,
    private loginService : LoginService){}

  ngOnInit(): void {
    this.getUserList();
    this.adminUserId = Number(this.storage.getObject(StorageItemType.USER_ID));
    this.userDetail = this.fb.group({
      user: ['', Validators.required],
      searchControl: ['']
    });

    this.userDetail.controls['user'].valueChanges.subscribe(value => {
      if(value) {
        this.userDetail.controls['searchControl'].setValue('');
        this.allUserListData = this.allUserList;
      }
    });
  }

  getUserList() {
    const userId = Number(this.storage.getObject(StorageItemType.USER_ID));

    this.userService.userAllList(userId).subscribe({
      next: (res) => {
        this.allUserList = res?.data;
       
        this.allUserList = this.allUserListData = res?.data.map((element: any) => ({...element}));
      },
      error: (error) => {}
    });
  }

  loginAs() {
    this.userService.loginAs(Number(this.userDetail.value.user)).subscribe({
      next: (res) => {
        if(res?.data) {
          this.loginService.setUser(res, Number(this.adminUserId));
        }
      },
      error: (error) => {}
    });
  }  

  onKey(value: any) {
    this.allUserListData = this.allUserList
    let selectedUser = this.search(value.target.value)
    this.allUserListData = selectedUser;
  }


  search(value: string) {
    if (value === '') {
      return this.allUserListData; // Ensure this matches your data list
    } else {
      const filter = value.toLowerCase();
      return this.allUserListData.filter((option: any) => {
        return (
          option.firstName.toLowerCase().startsWith(filter) ||
          option.lastName.toLowerCase().startsWith(filter) ||
          option.mobileNumber.toLowerCase().startsWith(filter) ||
          option.roleName.toLowerCase().startsWith(filter)
        );
      });
    }
  }  

  onSearchValueClear() {
    if(!this.allUserListData.length){
      this.userDetail.controls['searchControl'].setValue('');
      this.allUserListData = this.allUserList;
    }
  }
}
