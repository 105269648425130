import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { StorageService } from './storage.service';
import { StorageItemType } from '../constants/storageitem';
import { CryptoService } from './crypto.service';
import { PermissionTypeCode } from '../constants/permissioncode';

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const storage = inject(StorageService);
  const crypto = inject(CryptoService)

  const permission = JSON.parse(JSON.parse(storage.getObject(StorageItemType.USER_PERMISSIONS)));
  const hasPermission = route?.data;

  const allowedMenu = permission?.find(permission => permission?.menuCode === hasPermission['menuCode'] && permission?.permissionTypeCode === PermissionTypeCode.Deny)
  if (allowedMenu?.permissionTypeCode === PermissionTypeCode.Deny && allowedMenu?.hasPermission === true) {
    router.navigate(['auth/unauthorized']);
    return false;
  } else if (!authService.isAuthorized()){
    router.navigate(['auth/side-login']);
    return false;
  }

  return true;
};
