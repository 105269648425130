export enum Images {

    REGULAR = 'assets/images/svgs/Regular.png',
    ADVANCE = 'assets/images/svgs/Advance.png',
    PREMIUM = 'assets/images/svgs/Premium.png',
    IN_STORE = 'assets/images/svgs/Instore.svg',
    DOORSTEP = 'assets/images/svgs/Doorstep.svg',
    PICK_AND_DROP = 'assets/images/svgs/pick and drop.svg',
    AMC = 'assets/images/svgs/AMC.svg',
    AMC_SERVICE = 'assets/images/svgs/AMC service.svg',
    BATTERY = 'assets/images/svgs/Battery.svg',
    BODY_PARTS = 'assets/images/svgs/Body Parts.svg',
    CHAIN = 'assets/images/svgs/chain.svg',
    CLUTCH = 'assets/images/svgs/Clutch.svg',
    COOLING_SYSTEM = 'assets/images/svgs/Cooling System Services.svg',
    ENGINE = 'assets/images/svgs/Engine.svg',
    EV = 'assets/images/svgs/EV.svg',
    EXHAUST_SYSTEM = 'assets/images/svgs/Exhaust System Services.svg',
    FUEL = 'assets/images/svgs/FUEL.svg',
    GEAR = 'assets/images/svgs/Gear.svg',
    IGNITION_SYSTEM = 'assets/images/svgs/Ignition System.svg',
    SAFETY = 'assets/images/svgs/Safety Inspection Services.svg',
    SUSPENSION = 'assets/images/svgs/Suspension.svg',
    TIRE = 'assets/images/svgs/Tyre.svg',
    WIRING = 'assets/images/svgs/Wiring.svg',
    USER_1 = 'assets/images/profile/user-1.jpg',
    IN_STORE_PNG = '../../../assets/images/svgs/In store service.png',
    PICK_AND_DROP_PNG = '../../../assets/images/svgs/Pickup and drop service.png',
    DOORSTEP_PNG = '../../../assets/images/svgs/Doorstep service.png',
    PDF_ICON = 'assets/images/svgs/pdf-icon.svg',
    IMAGE_NOT_AVAILABLE = 'assets/images/data-not-found/image-not-available.svg',

    HEADER_ICON_VEHICLE = 'assets/images/breadcrumb/My Vehicles.png',
    HEADER_ICON_ACCOUNT_SETTING = 'assets/images/breadcrumb/Account Setting.png',
    HEADER_ICON_ADMIN = 'assets/images/breadcrumb/Admin.png',
    HEADER_ICON_BOOK_SERVICE = 'assets/images/breadcrumb/Book Service.png',
    HEADER_ICON_GARAGE = 'assets/images/breadcrumb/Garage Registration.png',
    HEADER_ICON_GARAGE_SETTING = 'assets/images/breadcrumb/Garage Settings.png',
    HEADER_ICON_ON_GOING = 'assets/images/breadcrumb/On Going Services.png',
    HEADER_ICON_SERVICE_HISTORY = 'assets/images/breadcrumb/Service History.png',
    HEADER_ICON_SERVICE_INVOICE = 'assets/images/breadcrumb/Service Invoice.png',
    HEADER_ICON_USER_REGISTRATION = 'assets/images/breadcrumb/User Registration.png',
    HEADER_ICON_FAQ = 'assets/images/breadcrumb/FAQ.png',
    HEADER_ICON_FEEDBACK = 'assets/images/breadcrumb/feedback.png',
    HEADER_ICON_HELP = 'assets/images/breadcrumb/help and support.png',
}