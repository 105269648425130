import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  constructor() { }

  tokenFromUI = 'E53DD8585A7193C4';

  public encrypt(obj: any): string {
    const encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(obj),
      CryptoJS.enc.Utf8.parse(this.tokenFromUI),
      {
        keySize: 16,
        iv: CryptoJS.enc.Utf8.parse(this.tokenFromUI),
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return encrypted.toString();
  }

  public decrypt(objString: string): string {
    if (objString === undefined || objString === null) {
      return null;
    }

    const decrypted = CryptoJS.AES.decrypt(
      objString,
      CryptoJS.enc.Utf8.parse(this.tokenFromUI),
      {
        keySize: 16,
        iv: CryptoJS.enc.Utf8.parse(this.tokenFromUI),
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString(CryptoJS.enc.Utf8);
    return decrypted;
  }

}
