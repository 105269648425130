export class PermissionMenuCode {

    public static Garage = "GARREG";
    public static AccountSetting = "ACCTSET";
    public static GarageSetting = "GARSET";
    public static OnGoingService = "ONGSERV";
    public static ServiceHistory = "SERHIS";
    public static ServiceInvoice = "SERVIN";
    public static UserRegistration = "USRREG";
    public static MyVehicle = "MYVHCL";
    public static BookService = "BKSRV";
    public static Admin = "ADMIN";
    public static ServiceDetails = "SDETALS";
    public static JobCard = "JBCARD";

}

export class PermissionTypeCode {

    public static FullAccess = "FULL";
    public static ReadOnly = "READ";
    public static Add = "ADD";
    public static Edit = "EDIT";
    public static Delete = "DELETE";
    public static Deny = "DENY";

}

export class RoleCode {
    public static Admin = "ADMIN";
    public static GarageOwner = "GRO";
    public static VehicleOwner = "VEHO";
}