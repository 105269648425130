import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// icons
import { TablerIconsModule } from 'angular-tabler-icons';
import * as TablerIcons from 'angular-tabler-icons/icons';

// perfect scrollbar
import { NgScrollbarModule } from 'ngx-scrollbar';

//Import all material modules
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//Import Layouts
import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';

import { FilterPipe } from './shared/pipe/filter.pipe';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AuthInterceptor } from './shared/interceptor/auth.interceptor';
import { MatNativeDateModule } from '@angular/material/core';
import { CustomTranslationLoaderService } from './custom-translation-loader.service';
import { LoaderComponent } from './layouts/loader/loader.component';
import { GtmService } from './shared/services/gtm.service';

export function initializeGtm(gtmService: GtmService) {
  return (): Promise<void> => {
    return new Promise<void>((resolve) => {
      gtmService.loadGtm();
      resolve();
    });
  };
}

@NgModule({ declarations: [AppComponent, BlankComponent, FilterPipe, LoaderComponent],
    exports: [TablerIconsModule],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        MatNativeDateModule,
        TablerIconsModule.pick(TablerIcons),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: CustomTranslationLoaderService,
                deps: [HttpClient],
            },
        }),
        NgScrollbarModule,
        FullComponent], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeGtm,
            deps: [GtmService],
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}
